












































import EmailUtils, { Email } from '@/utils/EmailUtils';
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: Object as () => Partial<Email>,
    },
    mode: {
      type: String as () => 'create' | 'edit',
      required: true,
    },
    errors: {
      type: Object as () => { [key: string]: string[] },
      default: () => ({}),
    },
  },

  data(): {
    internal: Partial<Email>;
  } {
    return {
      internal: EmailUtils.generateDefault(),
    };
  },

  watch: {
    value: {
      handler(value) {
        this.internal = EmailUtils.generateDefault(value);
      },
    },

    'internal.key': {
      handler(value) {
        this.value.key = value;
      },
    },

    'internal.name': {
      deep: true,
      handler(value) {
        if (Object.values(value).some((value) => !!value)) {
          this.value.name = value;
        } else {
          this.value.name = undefined;
        }
      },
    },

    'internal.subject': {
      deep: true,
      handler(value) {
        if (Object.values(value).some((value) => !!value)) {
          this.value.subject = value;
        } else {
          this.value.subject = undefined;
        }
      },
    },

    'internal.body': {
      deep: true,
      handler(value) {
        if (Object.values(value).some((value) => !!value)) {
          this.value.body = value;
        } else {
          this.value.body = undefined;
        }
      },
    },

    'internal.signature': {
      deep: true,
      handler(value) {
        if (Object.values(value).some((value) => !!value)) {
          this.value.signature = value;
        } else {
          this.value.signature = undefined;
        }
      },
    },
  },
});
