import axios from 'axios';
import APIUtils from './APIUtils';
import LocaleUtils, { LanguageObject } from './LocaleUtils';

interface Email {
  key: string;
  name: LanguageObject;
  subject?: LanguageObject;
  body?: LanguageObject;
  signature?: LanguageObject;
}

const generateDefault = (item?: Partial<Email>) => {
  return {
    key: item?.key,
    name: LocaleUtils.langobj.generate(item?.name),
    subject: LocaleUtils.langobj.generate(item?.subject),
    body: LocaleUtils.langobj.generate(item?.body),
    signature: LocaleUtils.langobj.generate(item?.signature),
  };
};

const api = {
  list: async () => {
    const resp = await axios.get<Email[]>('email/list.php');
    return resp.data;
  },
  get: async (key: string) => {
    const resp = await axios.get<Email>('email/get.php', {
      params: { key: key },
    });
    return resp.data;
  },
  create: async (item: Partial<Email>) => {
    const resp = await axios.post<Email>('email/create.php', {
      ...APIUtils.clean(item),
    });
    return resp.data;
  },
  update: async (item: Partial<Email>) => {
    const resp = await axios.put<Email>('email/update.php', {
      ...APIUtils.clean(item),
    });
    return resp.data;
  },
  remove: async (key: string) => {
    const resp = await axios.delete<Email>('email/remove.php', {
      params: { key: key },
    });
    return resp.data;
  },
};

export default { api, generateDefault };
export { Email };
