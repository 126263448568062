
















import EmailForm from '@/components/email/EmailForm.vue';
import APIUtils from '@/utils/APIUtils';
import EmailUtils, { Email } from '@/utils/EmailUtils';
import Vue from 'vue';

export default Vue.extend({
  components: { EmailForm },

  data(): {
    item?: Partial<Email>;
    state: {
      value?: Partial<Email>;
      errors: undefined;
      isValid: boolean;
      isSubmit: boolean;
      isDelete: boolean;
    };
  } {
    return {
      state: {
        value: undefined,
        errors: undefined,
        isValid: false,
        isSubmit: false,
        isDelete: false,
      },
    };
  },

  async mounted() {
    try {
      this.state.value = EmailUtils.generateDefault();
      (this.$refs.form as any).resetValidation();
    } catch (error) {
      this.state.errors = APIUtils.error(error);
    }
  },

  methods: {
    async handleSubmit() {
      try {
        this.state.isSubmit = true;
        this.state.errors = undefined;

        if (this.state.value) {
          (this.$refs.form as any).validate();
          await EmailUtils.api.create(this.state.value);
          this.$router.replace({ name: 'email-list' });
          this.$toast.push({
            text: this.$t('success.create', [this.$tc('email.label')]),
            type: 'success',
          });
        }
      } catch (error) {
        this.state.errors = APIUtils.error(error);
        this.$toast.push({
          text: this.$t('error.create', [this.$tc('email.label')]),
          type: 'error',
        });
      } finally {
        this.state.isSubmit = false;
      }
    },
  },
});
